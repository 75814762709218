/* You can add global styles to this file, and also import other style files */
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/ng2-dnd/bundles/style.css";


html,
body {
  width: 100% !important;
  height: 100% !important;
}

body {
  padding-top: 60px;
  font-size: 12px;
}

.modal-dialog {

  width: 1200px !important;
  max-width: 1200px !important;

}


/*
@media (min-width: 576px){
  .page-modal .modal-dialog{
    width:800px !important;
    max-width: 800px !important;
  }
}
*/


#doc-close{
  /*
  position: absolute;
  z-index: 1;
  right: 70px;
  top: 100px;
  */
  border-radius: 18px;
  /*box-shadow: 10px 10px 29px -8px rgba(0,0,0,0.75);*/
  /*
  width: 30px;
  height: 30px;
  */

}

#mailbox-list{
  max-height: 600px;
  overflow-y: scroll
}

#mailbox-list .file-icon{
  font-size: 10px;
  padding: 1px 4px;
  line-height: 1.5;
}

#mailbox-list .list-group-item{
  padding-left: 8px;
  /*padding-right: 8px;*/

  padding-top: 4px;
  padding-bottom: 4px;
}

#mailbox-list .list-group-item.active{
  background-color: #495057;
  border-color: #6c757d;
}

#mailbox-list division-select > select,
#mailbox-list status-select > select{
  width:78px;
  font-size: 10px !important;
  font-weight: bold;
  height: 24px !important;
}

#mailbox-list .status-select option{
  font-weight: bold;
}


.multiselect-dropdown .selected-item,
.dropdown-list .multiselect-item-checkbox{
  font-size: 11px !important;
}

.form-inline .multiselect-dropdown .selected-item,
.form-inline .dropdown-list .multiselect-item-checkbox{
  font-size: 12px !important;
}

.dropdown-list{
  /*width:150% !important;*/
  min-width: 220px;
}


.multiselect-dropdown .dropdown-btn{
  padding: 4px 10px !important;
}


#spinner{
  z-index: 9;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0e0e0e;
  opacity: 0.7;
}

#spinner > span{
  font-size: 32px;
  color: #fff;
  position: relative;
  top: 200px;
  left: 25%;
}


.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.mail-item-list{
  font-size: 12px;
}

.mail-item-list division-select .division-status,
.mail-item-list status-select .doc-status{
  font-size: 10px;
  width: 95px;
}

.mail-item-list division-select select option,
.mail-item-list status-select select option{
  font-size: 10px !important;

}


.popover{
  max-width: 720px;
}
